<template>
  <div class="pa-5">
    <v-row class="justify-center">
      <span style="font-size: 2rem">ຜະລິດຕະພັນຂອງພວກເຮົາ</span>
    </v-row>
    <v-row class="justify-center">
      <!-- <v-col cols="12" md="4" lg="3" xl="2" v-for="(item, i) in items" :key="i">
        <v-hover v-slot="{ hover }" close-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            height="500"
          >
            <v-card-title primary-title>
              <v-img height="300" contain :src="require('@/assets/images/payment.gif')"></v-img>
            </v-card-title>
            <v-card-text> 
            <v-row no-gutters class="justify-center mb-2"><span style="font-size:1.5rem">{{item.title}}</span></v-row>
            <div style="font-size:1.1rem">{{item.description}} {{item.src}}</div>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col> -->
      <v-col cols="12" md="4" lg="3" xl="2">
        <v-hover v-slot="{ hover }" close-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            height="500"
          >
            <v-card-title primary-title>
              <v-img
                height="300"
                contain
                :src="require('@/assets/images/sim_add.gif')"
              ></v-img>
            </v-card-title>
            <v-card-text>
              <v-row no-gutters class="justify-center mb-2"
                ><span style="font-size: 1.5rem"> ເຕີມເງີນໂທລະສັບ </span></v-row
              >
              <div style="font-size: 1.1rem">
                ພວກເຮົາຢູ່ໃນທຸລະກິດທີ່ມີຄວາມເຂັ້ມແຂງ, ວ່ອງໄວ, ສາມາດປັບຂະຫຍາຍໄດ້ ແລະ
                ປ່ຽນແປງທາງດ້ານດິຈິຕອລ ແລະ ໂປແກຼມເຄື່ອນທີ່ໄດ້ດີ. ສຳລັບລູກຄ້າຂອງພວກເຮົາ.
              </div>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
<v-col cols="12" md="4" lg="3" xl="2">
        <v-hover v-slot="{ hover }" close-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            height="500"
          >
            <v-card-title primary-title>
              <v-img
                height="300"
                contain
                :src="require('@/assets/images/payment.gif')"
              ></v-img>
            </v-card-title>
            <v-card-text>
              <v-row no-gutters class="justify-center mb-2"
                ><span style="font-size: 1.5rem"> ຊື້ຂາຍເງີນໂທສະສັບ </span></v-row
              >
              <div style="font-size: 1.1rem">
                ພວກເຮົາຢູ່ໃນທຸລະກິດທີ່ມີຄວາມເຂັ້ມແຂງ, ວ່ອງໄວ, ສາມາດປັບຂະຫຍາຍໄດ້ ແລະ
                ປ່ຽນແປງທາງດ້ານດິຈິຕອລ ແລະ ໂປແກຼມເຄື່ອນທີ່ໄດ້ດີ. ສຳລັບລູກຄ້າຂອງພວກເຮົາ.
              </div>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4" lg="3" xl="2">
        <v-hover v-slot="{ hover }" close-delay="200">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            height="500"
          >
            <v-card-title primary-title>
              <v-img
                height="300"
                contain
                :src="require('@/assets/images/percent.gif')"
              ></v-img>
            </v-card-title>
            <v-card-text>
              <v-row no-gutters class="justify-center mb-2"
                ><span style="font-size: 1.5rem"> ຮັບເປີເຊັນ </span></v-row
              >
              <div style="font-size: 1.1rem">
                ພວກເຮົາຢູ່ໃນທຸລະກິດທີ່ມີຄວາມເຂັ້ມແຂງ, ວ່ອງໄວ, ສາມາດປັບຂະຫຍາຍໄດ້ ແລະ
                ປ່ຽນແປງທາງດ້ານດິຈິຕອລ ແລະ ໂປແກຼມເຄື່ອນທີ່ໄດ້ດີ. ສຳລັບລູກຄ້າຂອງພວກເຮົາ.
              </div>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>

      
    </v-row>
  </div>
</template>
<script>
export default {
  data: () => ({
    items: [
      {
        src: "@/assets/images/payment.gif",
        title: "ເຕີມເງີນໂທລະສັບ",
        description:
          "ພວກເຮົາຢູ່ໃນທຸລະກິດທີ່ມີຄວາມເຂັ້ມແຂງ, ວ່ອງໄວ, ສາມາດປັບຂະຫຍາຍໄດ້ ແລະ ປ່ຽນແປງທາງດ້ານດິຈິຕອລ ແລະ ໂປແກຼມເຄື່ອນທີ່ໄດ້ດີ. ສຳລັບລູກຄ້າຂອງພວກເຮົາ.",
      },
      {
        src: "@/assets/images/payment.gif",
        title: "ຊື້ຂາຍເງີນໂທສະສັບ",
        description:
          "ພວກເຮົາຢູ່ໃນທຸລະກິດທີ່ມີຄວາມເຂັ້ມແຂງ, ວ່ອງໄວ, ສາມາດປັບຂະຫຍາຍໄດ້ ແລະ ປ່ຽນແປງທາງດ້ານດິຈິຕອລ ແລະ ໂປແກຼມເຄື່ອນທີ່ໄດ້ດີ. ສຳລັບລູກຄ້າຂອງພວກເຮົາ.",
      },
      {
        src: "@/assets/images/percent.gif",
        title: "ຮັບເປີເຊັນ",
        description:
          "ພວກເຮົາຢູ່ໃນທຸລະກິດທີ່ມີຄວາມເຂັ້ມແຂງ, ວ່ອງໄວ, ສາມາດປັບຂະຫຍາຍໄດ້ ແລະ ປ່ຽນແປງທາງດ້ານດິຈິຕອລ ແລະ ໂປແກຼມເຄື່ອນທີ່ໄດ້ດີ. ສຳລັບລູກຄ້າຂອງພວກເຮົາ.",
      },
    ],
  }),
};
</script>
