<template>
  <v-app class="app">
    <v-system-bar height="50" color="red darken-3" app v-if="$vuetify.breakpoint.width > 1264">
      <v-spacer></v-spacer>
      <v-icon color="white" small>mdi-email</v-icon
      ><span class="mr-2 contact"> itc@itcapital.la</span>
      <v-icon color="white" small>mdi-phone</v-icon
      ><span class="mr-2 contact"> 020 79 999 969 </span>
      <v-icon color="white" small>mdi-clock</v-icon
      ><span class="mr-2 contact">Mon-Sun 08:00 - 00:00</span>
    </v-system-bar>
    <v-app-bar
      app
      color="#f0f0f0f0"
      height="100"
      flat
      v-if="$vuetify.breakpoint.width > 1264"
      style="border-bottom: 7px solid #C62828;"
    >
      <div style="width: 100%">
        <v-row no-gutters>
          <v-col cols="4">
            <div class="d-flex align-start">
              <v-img
                alt="Vuetify Name"
                class="shrink hidden-sm-and-down"
                contain
                min-width="100"
                :src="require('@/assets/images/ITC_logo.png')"
                height="100"
                max-width="180"
              />
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-cols>
            <div class="align-center pt-10">
              <v-row no-gutters>
                <a href="#about" class="mr-2">ກ່ຽວກັບ</a>
                <a href="#product" class="mr-2">ຜະລິດຕະພັນ</a>
                <a href="#team" class="mr-2">ທິມງານສະໜັບສະໜູນ</a>
                <a href="#team" class="mr-2">ສະໝັກງານ</a>
                <a href="#contact" class="mr-2">ຕິດຕໍ່ພວກເຮົາ</a>
              </v-row>
            </div>
          </v-cols>
        </v-row>
      </div>
      
    </v-app-bar>
    <v-app-bar
      app
      color="red darken-3"
      flat
      class="px-3"
      v-if="$vuetify.breakpoint.width < 1264"
    >
      <v-app-bar-nav-icon app @click.stop="drawer = !drawer" color="white">
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-icon color="white" small>mdi-email</v-icon
      ><span class="mr-2 contact"> itc@itcapital.la</span>
      <v-icon color="white" small>mdi-phone</v-icon
      ><span class="mr-2 contact"> 020 79 999 969 </span>
      <v-icon v-if="$vuetify.breakpoint.width > 650" color="white" small>mdi-clock</v-icon
      ><span class="mr-2 contact" v-if="$vuetify.breakpoint.width > 650"
        >Mon-Sun 08:00 - 00:00</span
      >
    </v-app-bar>
    <v-navigation-drawer
      v-if="$vuetify.breakpoint.width < 1264"
      v-model="drawer"
      app
      clipped
    >
      <v-row class="justify-center ma-1 mt-5">
        <v-img
          alt="Vuetify Name"
          class="shrink"
          contain
          min-width="100"
          :src="require('@/assets/images/ITC_logo.png')"
          height="100"
          max-width="180"
        />
      </v-row>
      <v-list dense>
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>
              <a href="#about">
                <v-list-item link rounded> ກ່ຽວກັບ </v-list-item>
              </a>
            </v-list-item-title>
            <v-list-item-title>
              <a href="#product">
                <v-list-item link>ຜະລິດຕະພັນ </v-list-item>
              </a>
            </v-list-item-title>
            <v-list-item-title>
              <a href="#team">
                <v-list-item link>ທິມງານສະໜັບສະໜູນ </v-list-item>
              </a>
            </v-list-item-title>
            <v-list-item-title>
              <a href="#team">
                <v-list-item link>ສະໝັກງານ </v-list-item>
              </a>
            </v-list-item-title>
            <v-list-item-title>
              <a href="#contact">
                <v-list-item link>ຕິດຕໍ່ພວກເຮົາ </v-list-item>
              </a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <div>
      <v-baner id="banner"></v-baner>
        <v-about id="about"></v-about>
        <v-product id="product"></v-product>
        <v-team id="team"></v-team>
        <v-contact id="contact"></v-contact>
      <v-foot id="footer"></v-foot>
      <div>
        <v-btn
          class="mb-16"
          color="#C62828"
          absolute
          bottom
          right
          fixed
          fab
          @click="goto"
        >
          <v-icon v-if="isIntersecting" color="white"
            >mdi-chevron-double-up</v-icon
          >
          <v-icon v-else color="white">mdi-chevron-double-down</v-icon>
        </v-btn>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    isIntersecting: 0,
    drawer: false,
  }),
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        this.isIntersecting = 0;
        return;
      }
      this.isIntersecting = currentScrollPosition;
    },
    goto() {
      this.onScroll();
      if (this.isIntersecting > 0) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>
<style >
.app {
  font-family: "Noto Sans Lao";
  font-size: 14px;
}
</style>