<template>
  <v-app class="app" >
    <v-main >
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
  export default {
    data: () => ({
    }),
    methods: {
    },
  }
</script>

<style lang="css">
@font-face {
  font-size: 1.8rem;
  font-family: "Noto Sans Lao";
  src: local("NotoSansLao"),
    url(./assets/fonts/NotoSansLao-Regular.ttf) format("truetype");
}
.description {
  font-size: 1.8rem;
  font-family: "Noto Sans Lao";
} 

html{
scroll-behavior: smooth;
}
.app {
  font-family: "Noto Sans Lao";
  font-size: 14px;
}
  .v-application a:link, .v-application a:visited,.v-application a:active{
    color: black;
    text-decoration: none;
  }
  .v-application a:hover{
    color: black;
    text-decoration: underline;
    text-decoration-color: #C62828;
    text-decoration-thickness: 2px;
  }
  .contact{
    color:white
  }
</style>
