<template>
  <div>
    <v-carousel
      hide-delimiter-background
      show-arrows-on-hover
      cycle
      continuous
      delimiter-icon="mdi-minus"
      :interval="5000"
    >
      <v-carousel-item
        v-for="(item, i) in items"
        :key="i"
        :src="item.src"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>
<script>
export default {
  data: () => ({
    items: [
      { src: "https://firebasestorage.googleapis.com/v0/b/it-capital-938f0.appspot.com/o/slide1.jpeg?alt=media&token=abc6baed-c012-46e7-95d4-40b386251b1e" },
      { src: "https://firebasestorage.googleapis.com/v0/b/it-capital-938f0.appspot.com/o/slide3.jpeg?alt=media&token=e0b0ecab-3ee5-4ebc-8f7b-377eabee85a0" },
      {
        src:
          "https://firebasestorage.googleapis.com/v0/b/it-capital-938f0.appspot.com/o/slide2.jpeg?alt=media&token=7f890159-e24b-4531-ab2d-90a854a89f4f",
      },
    ],
  }),
};
</script>
<style lang="scss">
  .v-window {
  &-x-transition,
  &-x-reverse-transition,
  &-y-transition,
  &-y-reverse-transition {
    &-enter-active,
    &-leave-active {
      transition: 1.5s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }
  }
}
</style>
