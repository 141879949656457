
  import About from '@/components/About'
  import Contact from '@/components/Contact'
  import Job from '@/components/Job'
  import Product from '@/components/Product'
  import Team from '@/components/Team'
  import Service from '@/components/Policies'
  import Banner from '@/components/Banner'
  import Nav from '@/components/Navigation'
  import foot from '@/components/Footer'
function useFrameWork(app) {
  console.log(app)
}
function setComponents(app) {
    app.component('v-about', About)
    app.component('v-contact', Contact)
    app.component('v-job', Job)
    app.component('v-product', Product)
    app.component('v-team', Team)
    app.component('v-service', Service)
    app.component('v-baner', Banner)
    app.component('v-nav', Nav)
    app.component('v-foot', foot)
}
export {
  setComponents,
  useFrameWork
}
